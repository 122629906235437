<template>
  <v-container grid-list-md text-xs-center>
    <v-layout row wrap>
      <v-flex xs6 offset-xs3>
        <v-card elevation="2">
          <v-card-title>
            <h1>Reset</h1>
          </v-card-title>

          <v-card-text>

            <v-form v-model="valid" @submit.prevent="resetPass">
              <v-container>
                <v-flex xs6 offset-xs3>
                  <v-row>
                    <!-- <v-col cols="12" md="12">
                      <v-text-field v-model="form.email" :rules="emailRules" label="E-mail" required></v-text-field>
                    </v-col> -->
                    <v-alert v-if="this.resetPassFromError.mess" type="error">
                        {{ this.resetPassFromError.mess }}
                    </v-alert>
                    <v-col cols="12" md="12">
                      <v-text-field
                        v-model="form.password"
                        :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required, rules.min]"
                        :type="showPass ? 'text' : 'password'"
                        label="Password"
                        required
                        @click:append="showPass = !showPass"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-text-field
                        v-model="form.password_confirmation"
                        :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required, rules.min, passwordConfirmationRule]"
                        :type="showPass ? 'text' : 'password'"
                        label="Password"
                        required
                        @click:append="showPass = !showPass"
                      ></v-text-field>
                    </v-col>


                    <v-col cols="12" md="12">
                      <v-btn class="mr-4" @click="resetPass">submit</v-btn>
                    </v-col>

                  </v-row>
                </v-flex>
              </v-container>
            </v-form>
          </v-card-text>

        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ResetPassPage",
  components: {},
  props: {
    email: String,
    token: String,
  },
  data() {
    return {
      valid: false,
      showPass: false,
      form: {
        email: '',
        password: '',
        password_confirmation: '',
      },
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid",
      ],
      passwordRules: [(v) => !!v || "Password is required!!!!"],
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        //emailMatch: () => ('The email and password you entered don\'t match'),
      },
    };
  },
  computed: {
    ...mapGetters(["resetPassFromError"]),
    /*userId: function () {
      return this.userInfo.id;
    },*/
    passwordConfirmationRule() {
      return () => (this.form.password === this.form.password_confirmation) || 'Password must match'
    },
    /*emailFeedback: function() {
            if (this.authError.hasOwnProperty('errors') && this.authError.errors.hasOwnProperty('email')) {
                return this.authError.errors.email[0]
            } else {
                return ''
            }
        },
        passFeedback: function() {
            if (this.authError.hasOwnProperty('errors') && this.authError.errors.hasOwnProperty('password')) {
                return this.authError.errors.password[0]
            } else {
                return ''
            }
        },*/
  },
  methods: {
    //onSubmit () {},
    async resetPass() {
      //User.login(this.form)
      //console.log(this.form)
      this.$store.dispatch('resetPassword', {
        email: this.email,
        token: this.token,
        password: this.form.password,
        password_confirmation: this.form.password_confirmation,
      })
    },

    // getCode() {
    //     console.log('get that code')
    // }
  },
};
</script>

<style>
</style>
