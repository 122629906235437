<template>
  <div v-if="videos.length" class="video-section">
    <v-container fill-heightTTT fluid ma-0 pa-0>
      <v-row align="center" justify="center" class="track-bar">
        <!-- <v-img
          v-if="true"
          class=""
          height="300"
          gradient="to right, rgba(0,0,0,.9) 0%, rgba(0,0,0,.6) 30%, rgba(0,0,0,.1) 100%"
          :alt="this.getTrack.track_image.alt"
          :src="this.getTrack.track_image.url"
        /> -->
          <!-- :src="require('../assets/seminars_img/'+this.getPic(this.title))" -->
        <div class="overlay-content">
          <v-container fill-height fluidt grid-list-md text-xs-center>
            <v-row align="center" >
              <v-col cols="12" sm="4">
                <h2 >
                  <!-- {{ this.title }} -->
                  {{this.getTrack.track_display_label}}
                </h2>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-row>
    </v-container>

    <v-container fill-height grid-list-mdt text-xs-centert mt-6 mb-4 >
      <div class="title">
        <!-- <h3>{{ title }}</h3> -->
      </div>
      <!-- <div class="row"> -->
      <v-row align="stretch" justify="center">
        <!-- <div class="card-list"> -->
        <!-- <div v-for="(item, index) in videos" :key="index" class="col-md-6 col-lg-3"> -->
        <v-col
          v-for="(item, index) in this.videoItems" :key="index"
          cols="6" lg="4" md="4" sm="6" xs="6"
        >
          <VideoCard
            v-bind:key="index"
            :trackIndex="trackIndex"
            :itemIndex="index"
            :item="item"
          />
        </v-col>
      </v-row>
    </v-container>

    <!-- </div>
    </div> -->
  </div>
</template>

<script>
import VideoCard from "./VideoCard";
import { mapGetters } from "vuex";

export default {
  name: "VideoCardsSection",
  components: {
    VideoCard,
  },
  props: {
    trackIndex: String,
    // track: Object,
    videos: Array,
    title: String,
  },
  data: function () {
    return {
      picLookUp: {
        "default": "Worship-Afterglow.jpg",
        "J - Christian Walk": "Christian Walk.jpg",
        "B - Feast of Tabernacles": "Feast of Tabernacles.jpg",
        "A - Prepare the Way": "Feast Theme – Prepare the Way.jpg",
        "K - Holocaust and Antisemitism": "Holocaust and Antisemitism.jpg",
        "H - ICEJ @40": "ICEJ @40.jpg",
        "F - Israel Advocacy": "Israel Advocacy.jpg",
        "D - Israel and Christians": "Israel and Christians.jpg",
        "L - Israeli History": "Israeli History.jpg",
        "NFR - Language Seminars – French": "Language Seminars.jpg",
        "NES - Language Seminars – Spanish": "Language Seminars.jpg",
        "NCN - Language Seminars – Chinese": "Language Seminars.jpg",
        "NNO - Language Seminars – Norwegian": "Language Seminars.jpg",
        "NKO - Language Seminars – Korean": "Language Seminars.jpg",
        "NSU - Language Seminars – Finnish": "Language Seminars.jpg",
        "NDE - Language Seminars – German": "Language Seminars.jpg",
        "NRU - Language Seminars – Russian": "Language Seminars.jpg",
        "E - Messianic and Arab Believers": "Messianic and Arab Believers.jpg",
        "G - Prophetic Insights On Current Events": "Prophetic Insights On Current Events.jpg",
        "M - VIRTUAL TOURS with Sar-El Academy": "Virtual Tours.jpg",
      }
    };
  },
  computed: {
    ...mapGetters(['constData']),
    videoItems() {
      //console.log(this.videos)
      //var items = this.videos.filter(a => a.privacy == 'public')
      var items = this.videos
      /*items.sort(function(a, b) {
        var textA = a.title.toUpperCase();
        var textB = b.title.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });*/
      return items
    },
    getTrack() {
      //console.log(this.constData.layout.tracks)
      //console.log(this.trackIndex)
      var trackInd = (this.trackIndex == 'none') ? this.constData.layout.default_track : this.trackIndex
      //console.log(trackInd)
      var track = this.constData.layout.tracks.find(x => x.track_id == trackInd)
      //console.log(track)
      return track
    },
  },
  methods: {
    getPic(a) {
      if (Object.prototype.hasOwnProperty.call(this.picLookUp, a)) {
        return this.picLookUp[a]
      }
      return this.picLookUp['default']
    },
  },
  mounted() {
    //console.log(this.trackIndex)
  },



};
</script>
<style>
</style>
