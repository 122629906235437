<template>
  <!-- <v-container grid-list-md text-xs-center>
    <v-layout row wrap>
      <v-flex md6 xs12 offset-md3> -->
  <v-container fill-height>
    <template v-if="!this.doneInit">Loading...</template>
    <template v-else>
      <v-row align="stretch"  justify="center">
        <v-spacer></v-spacer>
        <v-col>
          <v-card outlined class="transparent1">
            <v-card-title class="justify-center">
              <prismic-rich-text :field="constData.layout.title_404"/>
            </v-card-title>

            <v-card-text>
              <prismic-rich-text :field="constData.layout.message_404"/>
            </v-card-text>
            <v-card-actions>
              <v-container>
                <v-row align="stretch"  justify="center">
                  <v-spacer></v-spacer>
                  <v-col>
                    <v-btn color="accent" rounded class="" large to="/" href="#">{{ constData.layout.cta_button_text_404 }}</v-btn>
                  </v-col>
                  <v-spacer></v-spacer>
                </v-row>
              </v-container>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "LoginPage",
  components: {},
  props: {
    dev: Boolean,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['constData', 'doneInit']),
  },
  methods: {
  },
};
</script>

<style>
.field {
  background-color: white;
}
.link {
  text-align: center;
}
</style>
