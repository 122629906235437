<template>
    <!-- <v-sheet class="overflow-hidden" style="position: relative;" > -->
    <!-- <div class="main-content"> -->

    <v-container fill-height grid-list-mdt text-xs-centert>
      <template v-if="!this.doneInit">Loading...</template>
      <template v-else>
        <!-- <v-container fill-height fluid> -->
            <!-- <v-layout row wrap>
                <v-flex xs8 offset-xs2> -->
                  <v-row align="center" justify="center">
                    <v-col md="8">

                    <v-flex xs6 offset-xs3>

                    </v-flex>
                    <v-flex xs6 offset-xs3>

                    </v-flex>
                    <v-card outlined class="transparent">
                        <v-card-title primary-title class="layout justify-center">
                            <h1 class="text-center">
                                {{ constData.layout.landing_title }}
                            </h1>
                        </v-card-title>
                        <v-card-title primary-title class="layout justify-center">
                            <!-- <img src="../assets/feastlogo-white-outline.svg"> -->
                          <v-container class="pb-0">
                            <v-row align="center" justify="center" class="" dense>
                              <v-col cols="8" md="6" lg="4" class="pa-0">

                                <v-img
                                    :alt="constData.layout.landing_image.alt"
                                    class="shrink mt-1"
                                    contain
                                    min-width="100"
                                    :src="constData.layout.landing_image.url"
                                    />
                                    <!-- alt="Feast of Tabernacles"
                                    src="../assets/feastlogo-white-outline.svg" -->
                              </v-col>
                            </v-row>
                          </v-container>

                        </v-card-title>

                        <v-card-title primary-title class="layout justify-center">
                            <!-- <img src="../assets/group-6.svg"> -->
                            <v-img
                              :alt="constData.layout.landing_featured_event_image.alt"
                              class="shrink mt-1"
                              contain
                              min-width="100"
                              :src="constData.layout.landing_featured_event_image.url"
                              />
                              <!-- alt="Prepare the way"
                              src="../assets/group-6.svg" -->
                        </v-card-title>

                        <v-card-text class="buttons">
                          <v-container>
                            <v-row v-if="!this.isLoggedUser" align="center" justify="space-around">
                              <v-col md="5" >
                                <v-btn color="accent" rounded class="" large to="/register" href="#">{{ constData.layout.register_button_text }}</v-btn>
                              </v-col>
                              <v-col md="5">
                                <v-btn color="accent" rounded class="" large to="/signin">{{ constData.layout.login_button_text }}</v-btn>
                              </v-col>
                            </v-row>
                            <v-row v-else align="center" justify="center">
                              <v-col md="5">
                                <v-btn color="accent" rounded class="" large to="/lobby" href="#">{{ constData.layout.lobby_button_text }}</v-btn>
                              </v-col>
                            </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                <!-- </v-flex>
            </v-layout> -->
            </v-col>
          </v-row>
        </template>
      </v-container>
  <!-- <v-parallax
    src="https://cdn.vuetifyjs.com/images/parallax/material.jpg"
    height="400"
    >
    </v-parallax> -->
    <!-- <v-container class="fill-height">
      <v-row
        align="center"
        justify="center"
      >
        <v-btn
          color="pink"
          dark
          @click.stop="drawer = !drawer"
        >
          Toggle
        </v-btn>
      </v-row>
    </v-container>

    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
    >
      <v-list-item>
        <v-list-item-avatar>
          <v-img src="https://randomuser.me/api/portraits/men/78.jpg"></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>John Leider</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense>

        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer> -->
  <!-- </v-sheet> -->
  <!-- </div> -->
</template>

<script>
  //import { Card, Button, Badge, InfoSection, FormGroupInput, Checkbox } from '@/components';
  //import { Select, Option } from 'element-ui'
  //import MainNavbar from "../layout/MainNavbar.vue";

  import { mapGetters } from 'vuex'

  //import {texts} from '@/constants/texts'

  export default {
    name: 'HomePage',
    metaInfo: {
        title: 'Home',
    },
    bodyClass: 'about-us',
    components: {
    },
    data() {
        return {
        drawer: null,
        items: [
          { title: 'Home', icon: 'dashboard' },
          { title: 'About', icon: 'question_answer' },
        ],
            form: {
                firstName: '',
                email: '',
                speciality: ''
            }
        }
    },
    computed: {
        ...mapGetters(['isLoggedUser', 'constData', 'doneInit']),
        constTxts() {
            //return texts
            return []
        }
    },
  }
</script>
<style>
.v-card__title {
  word-break: break-word !important;
  /* text-align: center; */
  width: 100%;
}
</style>
<style scoped>
.main-content {
    /* margin-top: 200px; */
}
h1 {
  /* width: 647px;
  height: 96px;*/
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  /*background-image: linear-gradient(to bottom, #eeeeee, #d8d8d8);
  font-family: Avenir;
  font-size: 70px;*/
  font-size: 50px;
  font-weight: 500;
  /*font-stretch: normal;
  font-style: normal;*/
  line-height: normal;
  letter-spacing: 1px;
  /*-webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;*/
  color: white;
}
.buttons {
  /* margin-top: 4em; */
}
.v-btn {
  /* background-color: #f6343f !important; */
  width: 100%;
}
.v-btn span {
    /* padding: 0 5em; */
    width: 14em;
    color: white;
}
</style>
