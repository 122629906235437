<template>
  <v-app>
    <router-view name="background"/>
    <router-view name="header"/>
    <v-main>
      <router-view/>
    </v-main>
    <router-view name="footer" class="pt-40" />

  </v-app>
</template>

<script>


export default {
  name: 'App',

  components: {
  },
  /*metaInfo: {
    titleTemplate: '%s ' + 'ICEJ Live Events',
    meta: [
      // { name: 'title', content: 'ICEJ Live Events' },
      { name: 'description', content: 'Bringing Israel and it’s blessing to your home.' },
    ],
  },*/

  data: () => ({
    //
  }),
};
</script>
<style>
</style>
