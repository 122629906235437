<template>
  <v-container fill-height grid-list-mdt text-xs-centert>
    <template v-if="!this.doneInit">Loading...</template>
    <template v-else>
      <!-- <v-container fill-height fluid> -->
        <!-- <v-layout row wrap>
          <v-flex xs8 offset-xs2> -->
      <v-row align="center" justify="center">
        <!-- <v-col lg="3" md="4" sm="12" v-for="(item, index) in consts.menuItems.filter(item => item.active)" :key="index" class="icon-item" link three-line > -->
        <!-- <v-col lg="3" md="4" sm="12" v-for="(item, index) in constData.menuItems.filter(item => item.active)" :key="index" class="icon-item" link three-line > -->
        <v-col lg="3" md="4" sm="12" v-for="(item, index) in constData.layout.dashboard" :key="index" class="icon-item" link three-line >

          <!-- <div class="icon-list">
            <div v-for="(item, index) in consts.menuItems" :key="index" class="icon-item" > -->
          <!-- <v-list-item-group v-model="group" active-class="deep-blue--text text--accent-4" class="icon-list"> -->
            <!-- <v-list-item v-for="(item, index) in consts.menuItems" :key="index" class="icon-item"
            link
            three-line
            :to="item.path ? { path: item.path } : null"
            :href="!item.path ? item.url : null"
            :target="!item.path ? '_blank' : null"
            > -->
            <v-list-item  class="icon-item"
            link
            three-line
            :to="item.local_path ? { path: item.local_path } : null"
            :href="!item.local_path ? item.button_link.url : null"
            :target="!item.local_path ? item.button_link.target : null"
            >
                <!-- <router-link :to="{ path: item.path }"> -->
                  <div class="oval" >
                    <v-container fill-height fluid>
                      <v-row align="center" justify="center">
                        <v-col >
                          <v-img
                            class="shrink mt-1"
                            contain
                            min-width="100"
                            :alt="item.icon.alt"
                            :src="item.icon.url"
                          />
                            <!-- :src="require('../assets/icons/'+item.icon)" -->
                          <!-- <prismic-image :field="item.icon"/> -->
                        </v-col>
                      </v-row>
                    </v-container>
                  </div>
                  <div class="label">
                    {{ item.button_text }}
                  </div>
                <!-- </router-link> -->



            <!-- </div>
          </div> -->
            </v-list-item>
          <!-- </v-list-item-group> -->

        </v-col>
      </v-row>
    </template>
  </v-container>

</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "LobbyPage",
  components: {},
  data() {
    return {
      group: null,
      /*items: [
        {
          icon: "np-live-3264182-ffffff.svg",
          label: 'Main Auditorium',
          path: '/',
        },
        {
          icon: "ic-view-comfy-48-px.svg",
          label: 'Seminars',
          path: '/seminars',
        },
        {
          icon: "np-prayer-1748022-ffffff.svg",
          label: '24/7 Prayer',
          path: '/',
        },
        {
          icon: "ic-search-48-px.svg",
          label: 'Resources',
          path: 'https://www.icejstore.com/',
        },
        {
          icon: "ic-info-outline-48-px.svg",
          label: 'About Us',
          path: '/',
        },
        {
          icon: "ic-mail-outline-48-px.svg",
          label: 'Contact Us',
          path: '/',
        },
      ],*/
    }
  },
  computed: {
    /*items1: function() {
      return constsJson.menuItems
    },*/
    /*items: function() {
      var output = []
      this.item_values.forEach(element => {
        output.push({...element, icon: '../assets/icons/'+element.icon})
      })
      console.log(output)
      return output
    },*/
    ...mapGetters(["consts", 'doneInit', 'constData']),
    userId: function () {
      return this.userInfo.id;
    },
  },
  methods: {
  },
};
</script>

<style scoped>
.icon-list {
  display: grid;
  grid-gap: 2em;
  grid-template-columns: repeat(4, 1fr);
}
.icon-item {
  display: block;
  color: white;
}
/*.icon-item:hover {
  color: transparent !important;
}*/
.icon-item .oval {
  /* padding: 3em; */
  margin: 0 auto;
  border-radius: 50%;
  /*width: 100%;
  padding-top: 100%;*/
  width: 200px;
  height: 200px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-image: linear-gradient(to bottom, #e7d98b, #cab253);
}
.icon-item .oval .v-image {
  margin: 0 auto;
  width: 96px;
  height: 96px;
}
.icon-item .label {
  color: white;
  text-decoration: none;
  text-align: center;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  /*font-family: Montserrat;*/
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1px;
  margin-top: 1em;
  padding-bottom: 1em;
}
</style>
