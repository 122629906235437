export default function getBrowserLocale(options = {}) {

  const defaultOptions = { countryCodeOnly: false }
  const opt = { ...defaultOptions, ...options }

  /*var lang = navigator.language || navigator.userLanguage || 'pt-BR'
  console.log(navigator.language)
  console.log(navigator.userLanguage)
  console.log(lang.toLowerCase().split('-')[0])*/

  const navigatorLocale = (navigator.languages !== undefined) ? navigator.languages[0] : navigator.language
  //console.log(navigatorLocale)

  if (!navigatorLocale) {
    return undefined
  }

  const trimmedLocale = opt.countryCodeOnly ? navigatorLocale.trim().split(/-|_/)[0] : navigatorLocale.trim()
  //console.log(trimmedLocale);

  return trimmedLocale
}
