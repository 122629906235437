<template>
  <div>
    <template v-if="!this.doneInit"></template>
    <template v-else>
      <v-app-bar app1 color="primary" dark flat height="80">
        <!-- height="70px" -->
        <!-- <v-app-bar color="deep-purple" dark > -->
        <v-container>
          <v-row align="center" justify="center" class="py-1">
            <v-col cols="5" sm="4" md="2">
              <v-app-bar-nav-icon
                v-if="this.isLoggedUser"
                @click="drawer = true"
              ></v-app-bar-nav-icon>
            </v-col>

            <v-col cols="2" sm="4" md="8">
              <router-link class="logo-link" to="/lobby">
                <v-img
                  class="shrink mt-1tt pa-6 d-none d-sm-block"
                  contain
                  max-height="50"
                  width="100%"
                  :alt="constData.layout.navbar_white_logo_desk.alt"
                  :src="constData.layout.navbar_white_logo_desk.url"
                />
                <v-img
                  class="shrink mx-auto mt-1tt pa-6 d-block d-sm-none"
                  contain
                  max-width="50"
                  :alt="constData.layout.navbar_white_logo_mobile.alt"
                  :src="constData.layout.navbar_white_logo_mobile.url"
                />
              </router-link>
            </v-col>

            <v-col cols="5" sm="4" md="2" class="py-10">
              <v-select
                hide-details="auto"
                :label="constData.layout.language_placeholder"
                :value=currentLangCode
                :items="langItems"
                @change="langChange"
                outlined
                :dense="isDense"
                item-text="label"
                item-value="code"
              ></v-select>

                <!-- v-model="$i18n.locale" -->
                <!-- :label="$t('language_label')" -->
            </v-col>
          </v-row>
        </v-container>
      </v-app-bar>

      <v-navigation-drawer
        v-if="this.isLoggedUser"
        app
        color="primary"
        v-model="drawer"
        temporary
      >
        <v-list nav dense>
          <v-list-item-group v-model="group" active-class="">
            <v-list-item
              v-for="(item, index) in constData.layout.menu_items"
              :key="index"
              class="icon-item"
              link
              color="white"
              :to="item.local_path ? { path: item.local_path } : null"
              :href="!item.local_path ? item.item_link.url : null"
              :target="!item.local_path ? item.item_link.target : null"
            >
              <v-list-item-icon>
                <v-img
                  v-if="item.item_icon"
                  class="shrink mt-1"
                  contain
                  min-width="50"
                  :alt="item.item_icon.alt"
                  :src="item.item_icon.url"
                />
              </v-list-item-icon>

              <v-list-item-title color="white">
                {{ item.button_text }}
              </v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <template v-slot:append>
          <div class="pa-2">
            <v-btn block color="accent" rounded @click="doLogout">
              {{ constData.layout.signout_button_text }}
            </v-btn>
          </div>
        </template>
      </v-navigation-drawer>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
//import constsJson from "../consts.json";
import i18n from "@/i18n";

export default {
  name: "MainNavbar",
  props: {
    transparent: Boolean,
    colorOnScroll: Number,
  },
  components: {},
  data() {
    return {
      drawer: false,
      group: null,
    };
  },
  computed: {
    ...mapGetters(["isLoggedUser", "consts", 'constData', 'doneInit', 'currentLangCode']),
    menuItems() {
      var lobbyItem = {
        icon: false,
        iconCode: "mdi-home-variant-outline",
        label: "lobby_link_title",
        path: "/lobby",
      };
      return [lobbyItem, ...this.consts.menuItems];
    },
    langItems() {
      //return this.consts.langs.map(function(item) {return {label: i18n.t(item.label), code:item.code}})
      /*return this.consts.langs.map(function (item) {
        return { label: i18n.t("lang_label_" + item.code), code: item.code };
      });*/
      var items = this.constData.layout.language_controller.filter(item => item.enable_or_disable).map(function (item) {
        return { label: item.label, code: item.code };
      });
      return items
    },
    isDense() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
  },
  methods: {
    doLogout(e) {
      e.preventDefault();
      this.$store.dispatch("logout");
    },
    langChange(selectLang) {
      //console.log(selectLang);
      this.$store.dispatch("changeLang", selectLang);
    },
  },
};
</script>

<style scoped>
header {
  /* box-shadow: none !important; */
}
/*.logo-link {
  display: block;
}*/
.logo {
  padding-top: 4px;
}
.navbar-brand img {
  height: 30px;
}
.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: white !important;
}
i {
  min-width: 50px;
  color: white !important;
}
.icon-item .oval .v-image {
  margin: 0 auto;
  width: 48px;
  height: 48px;
}
p {
  margin: 0 !important;
}
</style>
