<template>
  <div class="">
    <!-- <images-section></images-section> -->
    <!-- <template v-if="!this.doneInit">Loading...</template> -->
    <template v-if="!this.doneInit">Loading...</template>
    <template v-else>
      <v-parallax
        :src="constData.layout.auditorium_bg_image.url"
        height="fit-content"
      >
          <!-- src="../assets/seminars_img/seminar_hero.jpg" -->
        <v-row align="center" justify="center" class="para-overlay">
          <v-col class="text-center" cols="12">
            <!-- <h1 class="">
              {{ $t("main_auditorium_link_title") }}
            </h1> -->
            <template v-if="this.loadingVideos">Loading...</template>
            <template v-else>
              <v-container class="">
                <v-row align="center" justify="center">
                  <v-col cols="12" xl="10" >
                    <div id="player">
                      <vimeo-player ref="player" :video-url="constData.layout.live_stream_url" @ready="onReady" :player-height="height" :player-width="width"></vimeo-player>
                      <!-- :video-url="videoUrl" -->
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </template>
          </v-col>
        </v-row>
      </v-parallax>

    </template>
  </div>
</template>

<script>

import { mapGetters, mapActions } from "vuex";
import VueScrollTo from "vue-scrollto";

export default {
  name: "AuditoriumPage",
  bodyClass: "index-page",
  components: {
  },
  data: function () {
    return {
      player: false,
      playerLoaded: false,
			//videoID: '533529129',
      //videoUrl: 'https://vimeo.com/event/959721',
      //videoUrl: 'https://vimeo.com/544551627/bc10b90fbb',//544551627/bc10b90fbb
      //videoUrl: 'https://vimeo.com/video/544551627',
      videoUrl: 'https://vimeo.com/event/988008',
			//height: 540,
      //width: 960,
			options: {
        muted: true,
        autoplay: true,
			},
			playerReady: false,
    };
  },
  computed: {
    ...mapGetters(["activeStream", "currentStream", "streams", "doneInit", "loadingVideos", "videosLists", "currentVideo", "loadingVideos", 'constData']),
    selItem() {
      let output = this.videosLists.find(
        //(element) => element.key == "none"//Recently Live
        (element) => (element.key == this.constData.layout.default_track)
      );
      return output;
    },
    height() {
      return this.$vuetify.breakpoint.mdAndDown ? null : 540
    },
    width() {
      return this.$vuetify.breakpoint.mdAndDown ? null : 960
    },
  },
  async mounted() {
    //console.log("start init");
    //await this.getAllStreams();
    //await this.getAllVideos();
    //this.initPlayer();
    //console.log("end init");
  },
  methods: {
    //...mapActions(['init']),
    ...mapActions(["getAllStreams", "getAllVideos"]),
    async initPlayer() {
    },
    playItem(index) {
      if (this.playerLoaded === false) {
        this.initPlayer();
      }
      this.$store.dispatch('playStream', [index])
    },
		onReady() {
			this.playerReady = true
		},
		play () {
			this.$refs.player.play()
		},
		pause () {
			this.$refs.player.pause()
		},
  },
  watch: {
  },
  /*created() {
    console.log(this.loadingStreams)
    this.unwatch = this.$store.watch(
      (state, getters) => state.loadingStreams,
      (newValue, oldValue) => {
        console.log(`Updating from ${oldValue} to ${newValue}`);

        // Do whatever makes sense now
        if (newValue === 'success') {
          this.complex = {
            deep: 'some deep object',
          };
        }
      },
    );
  },
  beforeDestroy() {
    this.unwatch();
  },*/
};
</script>

<style scoped>
iframe {
  width: 100%;
}

.v-parallax__content { /*.para-overlay*/
  opacity: 0.83;
  background-image: linear-gradient(180deg, #002e6d 100%, #000000 0%);
}
.alert {
  margin-top: 2em;
  padding: 0 !important;
  background-color: black;
  text-align: center;
  font-size: 1.4em;
}
.alert .btn {
  margin-left: 1em;
}
.card-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2em;
}
.col-md-6 {
  margin-bottom: 2em;
}
.card {
  height: 100%;
}
.card .card-image {
  height: 50%;
  overflow: hidden;
}
.card .card-image img {
  /* height: 100%; */
  height: 100%;
  object-fit: cover;
  width: 100%;
  vertical-align: baseline;
}
</style>
