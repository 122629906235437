<template>
  <v-card class="card raised" v-on:click="playItem">
    <div class="card-image">
      <img class="img rounded" :src="this.coverImgSrc">
    </div>
    <v-card-title>
      {{this.item.name}}
    </v-card-title>

  </v-card>
</template>
<script>
  export default {
    name: 'videoCard',
    props: {
      trackIndex: String,
      itemIndex: Number,
      item: Object,
    },
    mounted() {
      //console.log(this.item)
    },
    computed: {
      coverImgSrc: function() {
        //return this.item.snapshots[0].download
        return this.item.pictures.sizes[2].link
      },
    },
    methods: {
      playItem() {
        this.$store.dispatch('playItem', [this.trackIndex, this.itemIndex])
      }
    },

  }
</script>
<style>
.card {
  cursor: pointer;
  margin-bottom: 0 !important;
}
</style>
