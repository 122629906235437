<template>
  <div class="">
    <template v-if="!this.doneInit">Loading...</template>
    <template v-else>

      <!-- <images-section></images-section> -->
      <v-parallax
        :src="constData.layout.seminars_bg_image.url"
        height="fit-content"
      >
      <!-- src="../assets/seminars_img/seminar_hero.jpg" -->
        <!-- src="../assets/laura-siegal-1xmuSigXQ2Y-unsplash.jpg" -->
        <v-row align="center" justify="center" class="para-overlay">
          <v-col class="text-center" cols="12">
            <!-- <h1 class="display-1 font-weight-thin mb-4"> -->
            <!-- <h1 class="">
                {{ $t("seminars_link_title") }}
              </h1> -->

            <template v-if="this.loadingVideos">Loading...</template>
            <template v-else>
              <v-container class="">
                <v-row align="center" justify="center">
                  <v-col cols="12" xl="10" >
                    <div id="player">
                      <vimeo-player ref="player" :video-url="videoUrl" @ready="onReady" :player-height="height" :player-width="width"></vimeo-player>

                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </template>
          </v-col>
        </v-row>
      </v-parallax>

      <template v-if="this.loadingVideos">Loading...</template>
      <template v-else>
        <v-sheet class="d-flex" color="primary">
          <v-container fill-height fluid>
            <v-row align="center" justify="center">
              <v-col cols="12" sm="4">
                <div class="container">
                  <div style="text-align:center; color:white; font-size:1.4em;">
                    <!-- {{ $t("pick_your_category") }} -->
                    <prismic-rich-text :field="constData.layout.choose_track_text"/>
                  </div>

                  <v-autocomplete
                    v-model="selOpt"
                    :items="this.tracks"
                    solo
                    color="white"
                    label="aaa"
                    item-text="title"
                    item-value="key"
                    @change="change"
                    class="pt-2"
                  ></v-autocomplete>
                    <!-- :label="this.tracks_title" -->
                  <!-- chips
                      rounded -->
                  <!-- :disabled="isUpdating" -->
                  <!-- item-text="title" -->
                  <!-- item-value="value" -->
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-sheet>

        <!-- <v-parallax
          src="../assets/seminars_img/Christian Walk.jpg"
          height="300"
        >
          <v-row align="center" justify="center" class="para-overlay">
            <v-col class="text-center" cols="12">
              <div class="cover-overlay">
              </div>
              Text
            </v-col>
          </v-row>
        </v-parallax> -->

        <!-- <v-container fill-height fluid ma-0 pa-0>
          <v-row align="center" justify="center" class="track-bar">
            <v-img
              v-if="true"
              class=""
              height="300"
              gradient="to right, rgba(0,0,0,.9) 0%, rgba(0,0,0,.6) 30%, rgba(0,0,0,.1) 100%"
              :src="require('../assets/seminars_img/'+this.getPic(selItem.title))"
            />
            <div class="overlay-content">
              <v-container fill-height fluidt grid-list-md text-xs-center>
                <v-row align="center" >
                  <v-col cols="12" sm="4">
                    <h2 >
                      {{ selItem.title }}
                    </h2>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </v-row>
        </v-container> -->

        <!-- <v-container fill-height fluid1 grid-list-md text-xs-center>
          <v-layout row wrap> -->
            <videoCardsSection
              :trackIndex="selItem.key"
              :videos="selItem.videos"
              :title="selItem.title"
            />
          <!-- </v-layout>
          <v-row align="center" justify="center">
            <v-col cols="12" sm="12"> </v-col>
          </v-row>
        </v-container> -->
        <!-- ----------------------------- FROM HERE IGNORE -----------------------------
          <v-tabs v-model="tab" background-color="blue" class="ma-4 mx-lg-auto">
            <v-tab v-for="(item, index) in this.videosLists" :key="index">
              <h3>{{ item.title }}</h3>
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item v-for="(item, index) in this.videosLists" :key="index">
              <videoCardsSection :trackIndex="index" :videos="item.videos" :title="item.title"/>
            </v-tab-item>
          </v-tabs-items> -->

        <!-- <h2 class="title">Videos</h2>
                  <div v-for="(item, index) in this.videosLists" :key="index">
                    <videoCardsSection :trackIndex="index" :track="item" />
                  </div> -->
      </template>
    </template>
  </div>
</template>

<script>
import VideoCardsSection from "../components/VideoCardsSection";

import { mapGetters, mapActions } from "vuex";
import VueScrollTo from "vue-scrollto";

export default {
  name: "TracksPage",
  bodyClass: "index-page",
  components: {
    VideoCardsSection,
  },
  data: function () {
    return {
      player: false,
      playerLoaded: false,
      tab: null,
      //selOpt: "none",
      //selOpt: '',
      selVal: false,
      lastSel: {},
			videoID: '533529129',
      //videoUrl: 'https://vimeo.com/533529129/f29e96e98e',
			//height: 540,
      //width: 960,
			options: {
        muted: true,
        autoplay: true,
			},
			playerReady: false,
      //defTagKey: 'br2021_message_pastores_message_',
      defTagKey: 'br2021_lectures_lectures',
      selVideoUrl: false,
    };
  },
  computed: {
    ...mapGetters(["videosLists", "currentVideo", "loadingVideos", 'constData', 'doneInit']),
    tracks_title() {
      return this.selItem.title;
      //return this.$t('tracks_title')
    },
    /*selOptions() {
      const options = this.videosLists.map(function(item) {return {label:item.title, value:item}})
      console.log(options)
      return options
    },*/
    videoUrl: {
      get() {
        return (this.selVideoUrl === false) ? this.videosLists.find(item => (item.key == this.defTagKey)).videos[0].link : this.selVideoUrl
      },
      set(val) {
        this.selVideoUrl = val
      }
    },
    selOpt: {
      get() {
        //return 'default_track'
        return this.selVal ? this.selVal : this.constData.layout.default_track
        //return this.selVal ? this.selVal : this.defTagKey
      },
      set(val) {
        this.selVal = val
        return this.selVal
      }
    },
    selItem() {
      let output = this.videosLists.find(
        (element) => element.key == this.selOpt
      );
      return output;
    },
    tracks() {
      var trackItems = this.videosLists
      //var trackItems = [];
      //for (var i = 0; i < this.videosLists.length; i++) { trackItems[i] = this.videosLists[i]; }

      trackItems.sort(function(a, b) {
        var textA = a.title.toUpperCase();
        var textB = b.title.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
      //console.log(trackItems)
      return trackItems;
    },
    height() {
      return this.$vuetify.breakpoint.mdAndDown ? null : 540
    },
    width() {
      return this.$vuetify.breakpoint.mdAndDown ? null : 960
    },
  },
  async mounted() {
    //await this.init();
    // Make network request if the data is empty
    //if (Object.keys(this.videosLists).length === 0) {
    //console.log("start init");
    await this.getAllVideos();
    //console.log("end init");
    //this.selOpt = 'none'
    //console.log(this.videosLists)
    //console.log(this.tracks)
    //this.selItem = this.tracks.find(element => element.key == 'none')
    //console.log(this.selItem)
  },
  methods: {
    //...mapActions(['init']),
    ...mapActions(["getAllVideos"]),
    async initPlayer() {
      //console.log('initPlayer')
      /*this.$loadScript("//player.vimeo.com/api/player.js")
        .then((data) => {
          var options = {
            id: 533529129,
            width: 640,
            loop: true
          };
          this.player = new Vimeo.Player('player', options);
          this.player.on('play', function() {
            console.log('played the video!');
            });
          this.playerLoaded = true;
          //console.log(this.player)
        })
        .catch(() => {
          // Failed to fetch script
        });*/
    },
    change(a) {
      //console.log(a);
    },
		onReady() {
			this.playerReady = true
		},
		play () {
			this.$refs.player.play()
		},
		pause () {
			this.$refs.player.pause()
		}
  },
  watch: {
    selItem: function (newVideo) {
      //console.log(newVideo);
    },
    currentVideo: function (newVideo) {
      /*const testUri = newVideo.pictures.uri
      //const ptrn = '//videos/(.*):(.*)/pictures/(.*)/g'
      eslint-disable
      const ptrn = /\/videos\/\w*:(.*)\/pictures\/(.*)/;
      eslint-enable
      let match = ptrn.exec(testUri);
      console.log(match)
      if (match) {
        console.log(match[1])
      }*/

      this.videoUrl = newVideo.link
      this.$refs.player.play()
      this.$scrollTo("#player", 500, { offset: -160 });
    },
    doneInit: function (status) {
      //console.log(status)
      //if (status === true) {
        //this.getAllVideos();
      //}
    },
    loadingVideos: function (status) {
      /*if (status === false) {
        if (this.playerLoaded === false) {
          this.initPlayer();
        }
      }*/
    },
  },
};
</script>

<style>
/*.video-section {
  margin-top: 2em;
}*/
iframe {
  width: 100%;
}


.track-bar {
  position: relative;
}
.overlay-content {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  color: white;
}
/*.para-overlay*/
/* .v-parallax__content { */
/*`.v-parallax__image-container {
  opacity: 0.83;
  background-image: linear-gradient(180deg, rgba(255,0,0,.8) 100%, rgba(255,0,0,.2) 0%);
}*/
.cover-overlay {
  background-image: linear-gradient(180deg, rgba(0,0,0,.8) 100%, rgba(0,0,0,.6) 40%, rgba(0,0,0,.2) 0%);
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}
h1 {
  font-size: 3em !important;
  font-weight: bold !important;
  line-height: 2em;
}
.alert {
  margin-top: 2em;
  padding: 0 !important;
  background-color: black;
  text-align: center;
  font-size: 1.4em;
}
.alert .btn {
  margin-left: 1em;
}
.card-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2em;
}
.col-md-6 {
  margin-bottom: 2em;
}
.card {
  height: 100%;
}
.card .card-image {
  height: 50%;
  overflow: hidden;
}
.card .card-image img {
  /* height: 100%; */
  height: 100%;
  object-fit: cover;
  width: 100%;
  vertical-align: baseline;
}
</style>
