<template>
  <v-container fill-height>
    <v-row align="center" justify="center">
      <v-col md="8" sm="10">
        <v-card outlined class="transparent1">
          <v-card-title>
            <h1>{{ $t("forgot_password_title") }}</h1>
          </v-card-title>

          <v-card-text>

          <v-form v-model="valid" @submit.prevent="requestResetEmail">
            <v-container>
              <v-flex md6 xs12 offset-md3>
                <v-row>
                    <v-alert v-if="this.resetPassFromError.mess" type="error">
                        {{ this.resetPassFromError.mess }}
                    </v-alert>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12">
                    <v-text-field
                    v-model="form.email"
                    :rules="emailRules"
                    :label="$t('email_field_label')"
                    required
                    :error-messages="this.emailFeedback"
                    ></v-text-field>
                  </v-col>


                  <v-col cols="12" md="12">
                    <!-- <v-btn class="mr-4" @click="requestResetEmail">submit</v-btn> -->
                    <v-btn type="submit" class="mr-4" large color="accent" block>{{ $t('submit_label') }}</v-btn>
                  </v-col>

                </v-row>
              </v-flex>
            </v-container>
          </v-form>
          </v-card-text>

        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ForgotPage",
  components: {},
  data() {
    return {
      valid: false,
      showPass: false,
      form: {
        email: '',
      },
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid",
      ],
      passwordRules: [(v) => !!v || "Password is required!!!!"],
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        //emailMatch: () => ('The email and password you entered don\'t match'),
      },
    };
  },
  computed: {
    ...mapGetters(["resetPassFromError"]),
    userId: function () {
      return this.userInfo.id;
    },
    emailFeedback: function () {
      if (
        Object.prototype.hasOwnProperty.call(this.resetPassFromError, 'errors') &&
        Object.prototype.hasOwnProperty.call(this.resetPassFromError.errors, 'email')
      ) {
        return this.resetPassFromError.errors.email;
      } else {
        return "";
      }
    },
  },
  methods: {
    //onSubmit () {},
    async requestResetEmail() {
      //User.login(this.form)
      //console.log(this.form)
      this.$store.dispatch('requestResetEmail', {email: this.form.email})
    },

    // getCode() {
    //     console.log('get that code')
    // }
  },
};
</script>

<style>
</style>
