import Vue from "vue";
import Router from "vue-router";

import MainNavbar from './components/MainNavbar';
import MainFooter from './components/MainFooter';
import BackImage from './components/BackImage';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import VerifyPage from './pages/VerifyPage';
import ForgotPage from './pages/ForgotPage';
import ResetPassPage from './pages/ResetPassPage';
import LobbyPage from './pages/LobbyPage';
import TracksPage from './pages/TracksPage';
import AuditoriumPage from './pages/AuditoriumPage';
import PageNotFound from './pages/PageNotFound';

import store from './store'

Vue.use(Router);

/*const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isLoggedUser) {
    next()
    return
  }
  next('/')
}

const ifAuthenticated = (to, from, next) => {
  console.log('ifAuthenticated')
  if (store.getters.isLoggedUser) {
    next()
    return
  }
  next('/signin')
}*/

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: "/",
      name: "home",
      components: { default: HomePage, header: MainNavbar, background: BackImage, footer: MainFooter},
      props: {
        header: { transparent: false },
        background: { image: 'landing_og_image' },
        footer: { color: 'trans' }
      },
    },
    {
      path: "/register",
      name: "register",
      components: { default: RegisterPage, header: MainNavbar, background: BackImage, footer: MainFooter },
      props: {
        header: { transparent: false },
        background: { image: 'register_og_image' },
        footer: { color: 'trans' },
      },
    },
    {
      path: "/signin",
      name: "signin",
      //components: { default: LoginPage },
      components: { default: LoginPage, header: MainNavbar, background: BackImage, footer: MainFooter },
      props: {
        header: { transparent: false },
        background: { image: 'login_og_image' },
        footer: { color: 'trans' },
        default: route => ({
          dev: route.query.dev,
        })
      },
      meta: {
        hideForLogin: true
      },
      //beforeEnter: ifNotAuthenticated,
    },
    {
      path: "/verify",
      name: "verify",
      components: { default: VerifyPage, header: MainNavbar, background: BackImage, footer: MainFooter },
      props: {
        header: { transparent: false },
        background: { image: 'login_og_image' },
        footer: { color: 'trans' }
      },
      meta: {
        hideForLogin: true
      },
      //beforeEnter: ifNotAuthenticated,
    },
    {
      path: "/forgot",
      name: "forgot",
      components: { default: ForgotPage, header: MainNavbar, background: BackImage, footer: MainFooter },
      props: {
        header: { transparent: false },
        background: { image: 'login_og_image' },
        footer: { color: 'trans' }
      },
      meta: {
        hideForLogin: true
      },
    },
    {
      path: "/reset",
      name: "reset",
      components: { default: ResetPassPage, header: MainNavbar, background: BackImage, footer: MainFooter },
      props: {
        header: { transparent: false },
        background: { image: 'login_og_image' },
        footer: { color: 'trans' },
        //default: { token: 'abcd' }
        default: route => ({
          email: route.query.email,
          token: route.query.token,
        })
      },
      meta: {
        hideForLogin: true
      },
    },
    {
      path: "/lobby",
      name: "lobby",
      //components: { default: Videos },
      components: { default: LobbyPage, header: MainNavbar, background: BackImage, footer: MainFooter},
      props: {
        header: { transparent: false },//colorOnScroll: 65
        background: { image: 'lobby_bg_image' },//404_background_image
        footer: { color: 'trans' }
      },
      meta: {
        requiresLogin: true
      },
    },
    {
      path: "/seminars",
      name: "seminars",
      components: { default: TracksPage, header: MainNavbar, footer: MainFooter },
      props: {
        header: { transparent: false },//colorOnScroll: 65
        footer: { color: 'trans' }
      },
      meta: {
        requiresLogin: true
      },
    },
    {
      path: "/auditorium",
      name: "auditorium",
      components: { default: AuditoriumPage, header: MainNavbar, footer: MainFooter },
      props: {
        header: { transparent: false },
        footer: { color: 'trans' }
      },
      meta: {
        requiresLogin: true
      },
    },
    {
      path: "*",
      name: "404",
      components: { default: PageNotFound, header: MainNavbar, background: BackImage, footer: MainFooter },
      props: {
        header: { transparent: false },
        background: { image: '404_background_image' },
        footer: { color: 'trans' }
      },
      meta: {
        requiresLogin: false
      },
    }
  ],
  /*scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) {
      return {selector: to.hash}
    } else {
      return { x: 0, y: 0 }
    }
  },*/
});

router.beforeEach((to, from, next) => {
  //var accessToken = localStorage.getItem('accessToken')
  //console.log(accessToken)
  if (!store.getters.doneInit) {
    store.dispatch('init', {}).then(next)
      .catch(e => {
        console.log(e)
      })
  }
  if (to.matched.some(record => record.meta.requiresLogin) && !store.getters.isLoggedUser) {
  //if (to.matched.some(record => record.meta.requiresLogin) && (accessToken === null)) {
    //next({ path: '/signin' })
    next({ path: '/' })
  } else if (to.matched.some(record => record.meta.hideForLogin) && store.getters.isLoggedUser) {
  //} else if (to.matched.some(record => record.meta.hideForLogin) && (accessToken !== null)) {
    next({ path: '/seminars' })
  } else {
    next()
  }
})

export default router;
