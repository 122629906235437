import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'
import router from './router';


Vue.use(Vuex)


const baseEndPointUrl = 'https://sarel-live-br.frb.io/api';

const prismicDataResolver = function(inData) {
	var output = {};
	//console.log(process.env)

	//console.log(inData)
	//var layout = inData.results.find(x => x.type == 'layout').data//'type == 'layout' //X9DGpxIAACgAwoJ9
	//var layout = inData.results.find(x => ((x.type == 'layout') && (x.id == eventConf[process.env.VUE_APP_ICEJ_EVENT].layoutId))).data
	//var layout = inData.results.find(x => ((x.type == 'layout') && (eventConf[process.env.VUE_APP_ICEJ_EVENT].layoutId.includes(x.id)))).data
	var eventId = 'shavuot_brazil_2021';
	var layout = inData.results.find(x => ((x.type == 'layout') && (x.data.event_id == eventId) )).data


	output.layout = layout
	//console.log(output.layout)


	return output;
}

export default new Vuex.Store({
	//function builder(data) {
	//return new Vuex.Store({
	state: {
		accessToken: null,
		currentLangCode: 'en',
		user: null,
		authError: {
			mess: '',
			errors: {},
		},
		emailVerifyMessage: {
			type: '',
			text: '',
		},
		resetPassFormError: {
			mess: '',
			errors: {},
		},
		constData: {
			//menuItems: [],
			//lobbyItems: [],
		},
		videosInfo: [],
		tracks: [],
		streamsInfo: [],
		currentVideo: false,
		currentStream: false,
		doneInit: false,
		loadingVideos: true,
		loadingStreams: true,
	},
	mutations: {
		setDoneInit(state, val) {
			state.doneInit = val
		},
		setVideosLoading(state, LoadingStatus) {
			state.loadingVideos = LoadingStatus
		},
		setStreamsLoading(state, LoadingStatus) {
			state.loadingStreams = LoadingStatus
		},
		userLoginSuccess(state, userData) {
			state.accessToken = userData.accessToken
			state.user = userData.user
		},
		userLoginFail(state, error) {
			state.authError = error
		},
		userDisconnect(state) {
			state.accessToken = null
			state.user = null
		},
		userPassResetFail(state, error) {
			state.resetPassFormError = error
		},
		setEmailVerifyMessage(state, mess) {
			state.emailVerifyMessage = mess
		},
		setVideosInfo(state, videosInfo) {
			//console.log(videosInfo)
			//var defTrack = 'default_track'
			var defTrack = 'br2021_default_track_default_tra'
			let tracks = {
				/*'none': {
					'title': 'Recently Live',
					'videos': [],
				},*/
			}
			tracks[defTrack] = {
				'title': 'None for now',
				'videos': [],
			}
			videosInfo.videos.forEach((item, index) => {
				if (item.tags.length !== 0) {
					if (item.tags.map(x => x.name).includes('br2021')) {
						var itemTagNames = item.tags.filter(x => (x.name != 'br2021')).map(x => x.name)
						itemTagNames.forEach((tagName) => {
							if (tagName.startsWith('br2021_')) {//
							//if (tagName.startsWith('br2021__')) {//
								//var split = tagName.split('__')
								//var storeInTag = (split.length == 3) ? split[1] : defTrack
								//if (!Object.prototype.hasOwnProperty.call(tracks, storeInTag)) tracks[storeInTag] = { 'title': split[2], 'videos': [] }
								var storeInTag = tagName
								if (!Object.prototype.hasOwnProperty.call(tracks, storeInTag)) tracks[storeInTag] = { 'title': '.', 'videos': [] }
								tracks[storeInTag].videos.push(item)
							}
						})
					}
				}
			})
			//console.log(tracks)
			//state.tracks = tracks
			let tracksArr = []
			Object.entries(tracks).forEach((entry) => {
				const [key, value] = entry;
				//var videos = value.videos
				var videos = value.videos.sort(function (a, b) {
					var textA = a.name.toUpperCase();
					var textB = b.name.toUpperCase();
					return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
				});


				//console.log(key)
				var trackContent = state.constData.layout.tracks.find(x => x.track_id == key)
				if (trackContent == null) {
					trackContent = state.constData.layout.tracks.find(x => x.track_id == 'br2021_default_track_default_tra')
				}
				//console.log(trackContent)

				tracksArr.push({
					key: key,
					//title: value.title,
					title: trackContent.track_display_label,
					image: trackContent.track_image,
					videos: videos
				})
			})
			//console.log(tracksArr)
			state.tracks = tracksArr
		},
		setStreamsInfo(state, streamInfo) {
			//streamInfo.streaming_events.sort((a, b) => a.scheduled_start_time - b.scheduled_start_time)
			streamInfo.streaming_events.sort((a, b) => Date.parse(a.created) - Date.parse(b.created))
			state.streamsInfo = streamInfo
		},
		setCurrentVideo(state, [trackIndex, itemIndex]) {
			//state.currentVideo = state.tracks[trackIndex].videos[itemIndex]
			state.currentVideo = state.tracks.find(item => (item.key == trackIndex)).videos[itemIndex]
		},
		setCurrentStream(state, itemIndex) {
			state.currentStream = state.streamsInfo.streaming_events[itemIndex]
		},
		setConstData(state, response) {
			//console.log(response)
			state.constData = prismicDataResolver(response)
		},
		changeLang(state, lang) {
			state.currentLangCode = lang
		},
	},
	actions: {
		async init({ dispatch, commit, state }, initData) {
			/*return new Promise((resolve, reject) => {
				dispatch('autoLogin');
				//commit('setLoading', false)
				dispatch('loadConsts');
				commit('setDoneInit', true)
				resolve()
			});*/
			var locale = Vue.prototype.$cookies.get('locale') || 'pt'
			//console.log(locale)
			return Promise.all([
				commit('changeLang', locale),
				dispatch('autoLogin'),
				dispatch('loadConsts'),
			]).then((values) => {

				/*dispatch('getAllVideos').then(() => {
					commit('setDoneInit', true)
				})*/
				if (state.accessToken) {
					return Promise.all([
						dispatch('getAllVideos'),
						//dispatch('getAllStreams')
					]).then((values) => {
						commit('setDoneInit', true)
					});
				} else {
					commit('setDoneInit', true)
				}
			});
			/*return new Promise((resolve, reject) => {
			dispatch('autoLogin').then(() => {
				dispatch('loadConsts')
			})*/
		},
		changeLang({ dispatch, commit, state }, selectLang) {
			//var locale = Vue.prototype.$cookies.get('locale')
			//console.log(locale)
			Vue.prototype.$cookies.set('locale', selectLang)
			//locale = Vue.prototype.$cookies.get('locale')
			//console.log(locale)

			commit('changeLang', selectLang);
			return Promise.all([
				dispatch('loadConsts'),
			]).then((values) => {
				commit('setDoneInit', true)
			});
		},
		/*async loadConsts({ commit, state }) {
			console.log('in loadConsts')
			//var currentLang = this.$i18n.locale
			//var currentLang = 'en'
			var currentLang = state.currentLangCode;
			var prismicCode = state.consts.langs.find(x => x.code == currentLang).prismicCode;
			Vue.prototype.$prismic.client.query(
				//Vue.prototype.$prismic.Predicates.at('document.type', 'product-group'), {}
				'', { lang: prismicCode}
			).then((response) => {
				//console.log(response)
				commit('setConstData', response);
			})
		},*/
		loadConsts({ commit, state }) {
			return new Promise((resolve, reject) => {
				//var currentLang = state.currentLangCode;
				//var currentLang = Vue.prototype.$cookies.get('locale')
				//console.log(currentLang)
				var prismicCode = false;
				/*if (state.constData.layout) {
					//console.log(state.constData.layout.language_controller)
					prismicCode = state.constData.layout.language_controller.find(x => x.code == currentLang).prismic_code;*/
				if (state.currentLangCode == 'en') {
					prismicCode = 'en-us';
				} else {
					prismicCode = 'pt-br'
				}
				//console.log(prismicCode)
				Vue.prototype.$prismic.client.query(
					'', { lang: prismicCode }
				).then((response) => {
					//console.log(response)
					commit('setConstData', response);
					resolve()
				})
			})
		},
		async getAllVideos({ dispatch, commit, state }) {
			//console.log('in: getAllVideos')
			commit('setVideosLoading', true)
			//await dispatch('getVideos');
			//await axios.get('https://sarel-icej.frb.io/api/get-videos', {
			await axios.get(baseEndPointUrl+'/get-videos', {
				headers: {
					Authorization: `Bearer ${state.accessToken}`
				}
			}).then(result => {
				//console.log('done getAllVideos')
				commit('setVideosInfo', result.data)
				commit('setVideosLoading', false)
			}).catch(error => {
				console.log(error)
				//dispatch('logout')
			})
			//commit('setVideosLoading', false)
		},
		async getAllStreams({ dispatch, commit, state }) {
			commit('setStreamsLoading', true)
			//await new Promise(resolve => setTimeout(resolve, 5000));
			//await dispatch('getStream');
			//await axios.get('https://sarel-icej.frb.io/api/get-streaming-events', {
			await axios.get(baseEndPointUrl+'/get-streaming-events', {
				headers: {
					Authorization: `Bearer ${state.accessToken}`
				}
			}).then(result => {
				//console.log(result.data)
				commit('setStreamsInfo', result.data)
			}).catch(error => {
				//console.log(error)
				dispatch('logout')
			})

			//console.log('4')
			commit('setStreamsLoading', false)
		},
		verifyEmail({ commit }, VerData) {
			//axios.post('https://sarel-icej.frb.io/api/request-credentials', {
			axios.post(baseEndPointUrl+'/request-credentials', {
				email: VerData.email,
			}).then(result => {
				console.log(result)
				commit('setEmailVerifyMessage', {
					type: 'info',
					//message: 'Thank you for submitting your email. If you have already pre-registered, you will receive an email with your login credentials.',
					message: result.data.message,
				})
				//if (!result.data.hasOwnProperty('message')) {
				if (!Object.prototype.hasOwnProperty.call(result.data, 'message')) {
					/*commit('userLoginSuccess', {
						accessToken: result.data.access_token,
						user: result.data.user
					})
					router.push("/lobby")*/
				}
			}).catch(error => {
				if (error.response.data.message.length != 0) {
					commit('userLoginFail', {
						mess: error.response.data.message,
						//errors: error.response.data.hasOwnProperty('errors') ? error.response.data.errors : {},
						errors: Object.prototype.hasOwnProperty.call(error.response.data, 'errors') ? error.response.data.errors : {},
					})
				}
			})
		},


		signup({ commit }, authData) {
			//axios.post('https://sarel-icej.frb.io/api/register', {
			axios.post(baseEndPointUrl+'/register', {
				email: authData.email,
				password: authData.password,
			}).then(result => {
				localStorage.setItem('accessToken', result.data.idToken)
				localStorage.setItem('user', result.data.localId)
				commit('userLoginSuccess', {
					token: result.data.idToken,
					userId: result.data.localId
				})
			}).catch(error => console.log(error))
		},
		login({ commit }, authData) {
			//axios.post('https://sarel-icej.frb.io/api/login', {
			axios.post(baseEndPointUrl + '/login', {
				email: authData.email,
				password: authData.password,
			}).then(result => {
				//console.log(result)
				//if (!result.data.hasOwnProperty('message')) {
				if (!Object.prototype.hasOwnProperty.call(result.data, 'message')) {
					localStorage.setItem('accessToken', result.data.access_token)
					localStorage.setItem('user', JSON.stringify(result.data.user))
					commit('userLoginSuccess', {
						accessToken: result.data.access_token,
						user: result.data.user
					})
					router.push("/auditorium")
				} else {
					commit('userLoginFail', {
						mess: result.data.message,
						//errors: result.data.hasOwnProperty('errors') ? result.data.errors : {},
						errors: Object.prototype.hasOwnProperty.call(result.data, 'errors') ? result.data.errors : {},
					})
				}
			}).catch(error => {
				if (error.response.data.message.length != 0) {
					commit('userLoginFail', {
						mess: error.response.data.message,
						//errors: error.response.data.hasOwnProperty('errors') ? error.response.data.errors : {},
						errors: Object.prototype.hasOwnProperty.call(error.response.data, 'errors') ? error.response.data.errors : {},
					})
				}
			})
		},
		logout({ commit }) {
			commit('userDisconnect')
			localStorage.removeItem('accessToken')
			localStorage.removeItem('user')
			//console.log(localStorage.getItem('accessToken'))
			router.push("/")
		},
		/*async autoLogin({ commit }, videosJson) {
			const accessToken = localStorage.getItem('accessToken')
			if (!accessToken) { return }
			const user = JSON.parse(localStorage.getItem('user'))
			commit('userLoginSuccess', {
				accessToken: accessToken,
				user: user
			})
		},*/
		autoLogin({ commit }, videosJson) {
			return new Promise((resolve, reject) => {
				const accessToken = localStorage.getItem('accessToken')
				if (!accessToken) {
					resolve()
					return
				}
				const user = JSON.parse(localStorage.getItem('user'))
				commit('userLoginSuccess', {
					accessToken: accessToken,
					user: user
				})
				resolve()
			})
		},
		requestResetEmail({ commit }, resetData) {
			//axios.post('https://sarel-icej.frb.io/api/password/email', {
			axios.post(baseEndPointUrl + '/password/email', {
				email: resetData.email,
			}).then(result => {
				console.log(result)
				//if (!result.data.hasOwnProperty('message')) {
				/*if (!Object.prototype.hasOwnProperty.call(result.data, 'message')) {
					localStorage.setItem('accessToken', result.data.access_token)
					localStorage.setItem('user', JSON.stringify(result.data.user))
					commit('userLoginSuccess', {
						accessToken: result.data.access_token,
						user: result.data.user
					})
					router.push("/lobby")
				} else {
					commit('userLoginFail', {
						mess: result.data.message,
						//errors: result.data.hasOwnProperty('errors') ? result.data.errors : {},
						errors: Object.prototype.hasOwnProperty.call(result.data, 'errors') ? result.data.errors : {},
					})
				}*/
			}).catch(error => {
				if (error.response.data.message.length != 0) {
					commit('userPassResetFail', {
						//mess: error.response.data.message,
						mess: error.response.data.error,
						errors: Object.prototype.hasOwnProperty.call(error.response.data, 'errors') ? error.response.data.errors : {},
					})
				}
			})
		},
		resetPassword({ commit }, resetData) {
			//axios.post('https://sarel-icej.frb.io/api/password/reset', {
			axios.post(baseEndPointUrl + '/password/reset', {
				token: resetData.token,
				email: resetData.email,
				password: resetData.password,
				password_confirmation: resetData.password_confirmation,
			}).then(result => {
				console.log(result)
				//if (!result.data.hasOwnProperty('message')) {
				/*if (!Object.prototype.hasOwnProperty.call(result.data, 'message')) {
					localStorage.setItem('accessToken', result.data.access_token)
					localStorage.setItem('user', JSON.stringify(result.data.user))
					commit('userLoginSuccess', {
						accessToken: result.data.access_token,
						user: result.data.user
					})
					router.push("/lobby")
				} else {
					commit('userLoginFail', {
						mess: result.data.message,
						//errors: result.data.hasOwnProperty('errors') ? result.data.errors : {},
						errors: Object.prototype.hasOwnProperty.call(result.data, 'errors') ? result.data.errors : {},
					})
				}*/
			}).catch(error => {
				console.log(error.response)
				if (error.response.data.error.length != 0) {
					commit('userPassResetFail', {
						mess: error.response.data.error,
						//errors: Object.prototype.hasOwnProperty.call(error.response.data, 'error') ? error.response.data.error : {},
					})
				}
			})
		},
		/*fetchVideosInfo({ commit }) {
			commit('setVideosInfo', {
				videosInfo: videosJson
			})
		},*/
		playItem({ commit }, [trackIndex, itemIndex]) {
			commit('setCurrentVideo', [trackIndex, itemIndex])
		},
		playStream({ commit }, [itemIndex]) {
			commit('setCurrentStream', itemIndex)
		},
	},
	getters: {
		consts(state) {
			return state.consts
		},
		constData(state) {
			return state.constData
		},
		doneInit(state) {
			return state.doneInit
		},
		loadingVideos(state) {
			return state.loadingVideos
		},
		loadingStreams(state) {
			return state.loadingStreams
		},
		authError(state) {
			return state.authError
		},
		emailVerifyMessage(state) {
			return state.emailVerifyMessage
		},
		resetPassFromError(state) {
			return state.resetPassFormError
		},
		userInfo(state) {
			return (state.accessToken !== null) ? state.user : false
		},
		isLoggedUser(state) {
			return state.accessToken !== null
		},
		videosLists(state) {
			return state.tracks
		},
		streams(state) {
			if (state.streamsInfo.length == 0) {
				return []
			} else {
				return state.streamsInfo.streaming_events
			}
		},
		activeStream(state) {
			if (state.streamsInfo.length == 0) {
				return false
			} else {
				return state.streamsInfo.streaming_events.find(item => item.active)
				//return state.streamsInfo.streaming_events[0]
			}
		},
		currentVideo(state) {
			return state.currentVideo
		},
		currentStream(state) {
			return state.currentStream
		},
		currentLangCode(state) {
			return state.currentLangCode
		},

	}
})
//}
//export default builder
