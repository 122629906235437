<template>
    <div class="back-image">
      <template v-if="!this.doneInit"></template>
      <template v-else>
        <!-- <span :class="bgClass"></span> -->
        <div class="prismic-img">
          <!-- <prismic-image :field="constData.layout[this.bgImgObjName()]"/> -->
          <prismic-image :field="constData.layout[this.image]"/>
        </div>
        <span class="mask"></span>
      </template>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: 'BackImage',
  components: {
  },
  props: {
    image: String,
  },
  computed: {
    ...mapGetters(['doneInit', 'constData']),
    bgClass: function() {
      return 'bg ' + this.image
    },
  },
  methods: {
    bgImgObjName: function() {
      var imagesLookup = {
        img1: 'landing_og_image',
        img2: 'register_og_image',
        img3: 'login_og_image',
        //img4: 'landing_og_image',
      }
      return imagesLookup[this.image]
    },
  }
}

</script>

<style>
.bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  /* background: url( 'https://cdn.pixabay.com/photo/2016/03/26/13/09/organic-1280537_1280.jpg') no-repeat center center; */
  background-size: cover;
  background-color: white;
  /* transform: scale(1.1); */
  /* opacity: 0.09; */
  object-fit: cover;
}
/*.bg.img1 {
  background-image: url('../assets/1_Onboarding-1.jpg');
}
.bg.img2 {
  background-image: url('../assets/2-1_Login.jpg');
}
.bg.img3 {
  background-image: url('../assets/3_Dashboard.jpg');
}
.bg.img4 {
  background-image: url('../assets/2-2_Registration.jpg');
}*/

.mask {
  /* background-image: linear-gradient(181deg, #002e6d 99%, #000000 1%); */
  /* background-image: linear-gradient(181deg, rgba(0, 46, 109, 0.7) 99%, rgba(0, 0, 0, 0.7) 1%); */
  background-image: linear-gradient(181deg, rgba(0, 0, 0, 0.4) 99%, rgba(0, 0, 0, 0.4) 1%);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.back-image {
  /*position: relative;*/
}
.prismic-img {
  display: inline-block;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
}
.prismic-img img {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  object-fit: cover;
  z-index: 0;
}
</style>
