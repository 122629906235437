import Vue from 'vue'
import App from './App.vue'
import router from './router';
import vuetify from './plugins/vuetify';
import LoadScript from 'vue-plugin-load-script';
import VueScrollTo from 'vue-scrollto';
import vueVimeoPlayer from 'vue-vimeo-player'

import Vuex from 'vuex'
import store from './store'
//import i18n from './i18n'

import VueCookies from 'vue-cookies'

import PrismicVue from '@prismicio/vue'

Vue.config.productionTip = false

Vue.use(Vuex)
Vue.use(LoadScript)
Vue.use(VueScrollTo)
//Vue.use(VueMeta);
Vue.use(vueVimeoPlayer)
Vue.use(VueCookies);

Vue.use(PrismicVue, {
  endpoint: 'https://sar-el-live.cdn.prismic.io/api/v2',
});

new Vue({
  vuetify,
  store,
  router,
  //i18n,
  render: h => h(App)
}).$mount('#app')
