<template>
  <v-container fill-height>
    <v-row align="center" justify="center">
      <v-col md="8" sm="10">
        <v-card outlined class="transparent1">
          <v-card-title class="justify-center">
            <h1>{{ $t("verify_email_title") }}</h1>
          </v-card-title>

          <v-card-text>
            <v-form v-model="valid" @submit.prevent="verify" >
              <v-container>
                <v-flex md6 xs12 offset-md3>
                  <v-row>
                    <!-- <v-col cols="12" md="12">
                        <v-text-field
                        v-model="firstname"
                        :rules="nameRules"
                        :counter="10"
                        label="First name"
                        required
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="12">
                        <v-text-field
                        v-model="lastname"
                        :rules="nameRules"
                        :counter="10"
                        label="Last name"
                        required
                        ></v-text-field>
                    </v-col>-->
                    <v-alert v-if="this.emailVerifyMessage.message" :type=this.emailVerifyMessage.type>
                        {{ this.emailVerifyMessage.message }}
                    </v-alert>

                    <v-col cols="12" md="12">
                      <v-text-field
                        v-model="form.email"
                        :rules="emailRules"
                        :label="$t('email_field_label')"
                        required
                      ></v-text-field>
                    </v-col>
                    <!-- :error-messages="['Fatal error', 'Another error']" -->

                    <v-col cols="12" md="12">
                      <v-btn type="submit" class="mr-4" rounded large color="accent" block>{{ $t('submit_label') }}</v-btn>
                    </v-col>
                  </v-row>
                </v-flex>
              </v-container>
            </v-form>
          </v-card-text>

        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "VerifyPage",
  components: {},
  data() {
    return {
      valid: false,
      showPass: false,
      form: {
        email: "",
      },
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid",
      ],
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
      },
    };
  },
  computed: {
    ...mapGetters(["emailVerifyMessage"]),
    userId: function () {
      return this.userInfo.id;
    },
    /*emailFeedback: function () {
      if (
        Object.prototype.hasOwnProperty.call(this.authError, 'errors') &&
        Object.prototype.hasOwnProperty.call(this.authError.errors, 'email')
      ) {
        return this.authError.errors.email;
      } else {
        return "";
      }
    },*/
  },
  methods: {
    //onSubmit () {},
    async verify() {
      //User.login(this.form)
      //console.log(this.form)
      this.$store.dispatch("verifyEmail", {
        email: this.form.email,
      });
    },

    // getCode() {
    //     console.log('get that code')
    // }
  },
};
</script>

<style>
</style>
