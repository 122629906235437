<template>
  <!-- <v-container grid-list-md text-xs-center>
    <v-layout row wrap>
      <v-flex md6 xs12 offset-md3> -->
  <v-container fill-height>
    <v-row align="center" justify="center">
      <v-col md="8" sm="10">
        <v-card outlined class="transparent1">
          <v-card-title class="justify-center">
            <h1>{{ constData.layout.login_title }}</h1>
          </v-card-title>

          <v-card-text>
            <h2 v-if="this.dev">Coming soon!!!!</h2>
            <v-form v-if="!this.dev" v-model="valid" @submit.prevent="login" >
              <v-container>
                <v-flex md6 xs12 offset-md3>
                  <v-row>
                    <v-alert v-if="this.authError.mess" type="error">
                        {{ this.authError.mess }}
                    </v-alert>
                    </v-row>

                    <v-row>
                      <v-col cols="12" md="12">
                      <v-text-field
                        v-model="form.email"
                        :rules="emailRules"
                        :label="constData.layout.email_placeholder"
                        :error-messages="this.emailFeedback"
                        required
                      ></v-text-field>
                    </v-col>
                    </v-row>
                    <!-- :error-messages="['Fatal error', 'Another error']" -->

                    <v-row>
                      <v-col cols="12" md="12" class="field">
                      <v-text-field
                        v-model="form.password"
                        :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required, rules.min]"
                        :type="showPass ? 'text' : 'password'"
                        :label="constData.layout.password_placeholder"
                        :error-messages="this.passFeedback"
                        required
                        @click:append="showPass = !showPass"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="12">
                      <v-btn type="submit" class="mr-4" rounded large color="accent" block>{{ constData.layout.submit_button_text }}</v-btn>
                    </v-col>
                  </v-row>
                </v-flex>
              </v-container>
            </v-form>
          </v-card-text>

          <v-card-actions v-if="!this.dev">
            <v-container>
              <v-flex md6 xs12 offset-md3>
                <v-row  justify="center" align="center" >
                  <v-col align-self="center" class="link">
                    <router-link to="/verify">{{ constData.layout.verify_email_link_text }}</router-link>
                  </v-col>
                  <v-col class="link">
                    <router-link to="/register">{{ constData.layout.forgot_password_link_text }}</router-link>
                  </v-col>
                </v-row>
              </v-flex>
            </v-container>
        </v-card-actions>
        </v-card>
      <!-- </v-flex>
    </v-layout> -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "LoginPage",
  components: {},
  props: {
    dev: Boolean,
  },
  data() {
    return {
      valid: false,
      showPass: false,
      form: {
        email: "",
        password: "",
      },
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid",
      ],
      passwordRules: [(v) => !!v || "Password is required!!!!"],
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        //emailMatch: () => ('The email and password you entered don\'t match'),
      },
    };
  },
  computed: {
    ...mapGetters(["authError", 'constData']),
    userId: function () {
      return this.userInfo.id;
    },
    emailFeedback: function () {
      if (
        Object.prototype.hasOwnProperty.call(this.authError, 'errors') &&
        Object.prototype.hasOwnProperty.call(this.authError.errors, 'email')
      ) {
        return this.authError.errors.email;
      } else {
        return "";
      }
    },
    passFeedback: function () {
      if (
        Object.prototype.hasOwnProperty.call(this.authError, 'errors') &&
        Object.prototype.hasOwnProperty.call(this.authError.errors, 'password')
      ) {
        return this.authError.errors.password[0];
      } else {
        return "";
      }
    },
  },
  methods: {
    //onSubmit () {},
    async login() {
      //User.login(this.form)
      //console.log(this.form)
      this.$store.dispatch("login", {
        email: this.form.email,
        password: this.form.password,
      });
    },

    // getCode() {
    //     console.log('get that code')
    // }
  },
};
</script>

<style>
.field {
  background-color: white;
}
.link {
  text-align: center;
}
</style>
