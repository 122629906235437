<template>
  <!-- <v-container fill-height fluid1 grid-list-md text-xs-center> -->
  <!-- <v-layout row wrap> -->
  <!-- <v-container fill-heightTTT> -->
  <!-- <v-app-bar app1 color="primary" dark flat height="80"> -->
  <v-footer :color="this.bgCol" class="font-weight-medium">
    <template v-if="!this.doneInit"></template>
    <template v-else>

      <v-container class="pb-0">
        <v-row align="center" justify="center" class="" dense>
          <v-col cols="12" lg="6" class="pa-0">
            <v-card-title primary-title class="layout justify-center" style="color:black;">
              <prismic-rich-text :field="constData.layout.event_details"/>
            </v-card-title>
          </v-col>
        </v-row>
      </v-container>
      <v-container class="pb-4">
        <v-row align="center" justify="center" class="" dense>
          <v-col cols="12" class="">
            <div class="layout justify-center">
            <a :href="constData.layout.promo_banner_link.url" :target="constData.layout.promo_banner_link.target">
              <v-img
                class="shrink mx-auto"
                :alt="constData.layout.promo_banner.alt"
                :src="constData.layout.promo_banner.url"
              />
              <!-- contain -->
              <!-- max-width="50" -->
            </a>
            </div>
          </v-col>
        </v-row>
      </v-container>

      <v-container class="pb-0">
        <v-row align="stretch" justify="center">
          <div class="ma-1 our-part-txt">
            <prismic-rich-text :field="constData.layout.our_partners_text"/>
          </div>
        </v-row>
        <v-row align="stretch"  justify="center">
          <v-spacer></v-spacer>
          <!-- <v-col
            cols="6" lg="2" md="3" sm="6"
            v-for="(item, index) in logos"
            :key="index"
            class=""
          > -->
          <v-col
            cols="6" lg="2" md="3" sm="6"
            v-for="(item, index) in constData.layout.partners_logos"
            :key="index"
            class=""
          >
          <!-- v-for="(item, index) in constData.partnersLogos" -->
            <!-- <v-card class="card" :href="item.path" target="_blank" color="rgba(0, 0, 0, 0)"> -->
            <a :href="item.link.url" target="_blank">
              <v-img
                class="shrink"
                :alt="item.logo.alt"
                :title="item.logo.alt"
                contain
                :src="item.logo.url"
              />
            <!-- :src="require('../assets/logos/' + item.img)" -->
            <!-- </v-card> -->
            </a>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <v-row align="stretch" justify="center" class="ma-6">
          <v-col cols="12" lg="6" align-self="center">
            <!-- <a href="https://int.icej.org/" target="_blank"> -->
            <!-- <a :href="constData.layout.footer_logo_link.url" target="_blank"> -->
            <prismic-link :field="constData.layout.footer_logo_link">
              <v-img
                :alt="constData.layout.footer_logo.alt"
                class="shrink"
                contain
                max-height="50"
                :src="constData.layout.footer_logo.url"
              />
                <!-- src="../assets/ice-jlogo-wide.svg" -->
            <!-- </a> -->
            </prismic-link>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </v-footer>
</template>

<script>
import { mapGetters } from "vuex";
//import gql from 'graphql-tag'

export default {
  name: "MainFooter",
  props: {
    color: String,
  },
  components: {},
  /*apollo: {
    compLogos: gql`query{
      allLayouts{
        edges{
          node {
            email_placeholder,
            partners_logos {
              logo,
              link {
                _linkType
              }
            }
          }
        }
      }
    }`,
  },*/
  data() {
    return {};
  },
  computed: {
    bgCol: function () {
      return (this.color == 'trans') ? "rgba(255, 255, 255, 1)" : this.color
    },
    ...mapGetters(['doneInit', 'constData']),
  },
  methods: {},
};
</script>

<style scoped>
.part-col .divider {
  width: 2px;
  height: 86px;
  border: solid 2px #ffffff;
}
.part-col {
  position: relative;
}
.part-col .our-part-txt {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
}
.our-part-txt {
  color: black;
}
</style>
