<template>
  <v-container fill-height>
    <v-row align="center" justify="center">
      <v-col >
        <v-row align="center" justify="center">
          <v-spacer></v-spacer>
          <v-col>
            <h1 color="white" style="text-align: center;">
              {{ constData.layout.register_button_text }}
            </h1>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>

        <v-row align="stretch"  justify="center">
          <v-spacer></v-spacer>
          <v-col
            cols="12" lg="4" md="4" sm="6" xs="12"
            v-for="(item, index) in constData.layout.registration_options"
            :key="index"
            class=""
          >
          <!-- v-for="(item, index) in consts.registerPaymentPages.filter(item => item.active)" -->
            <v-card class="card raised " :href="item.ticket_link.url" :target="item.ticket_link.target">
              <v-img
                class="shrink"
                contain
                :alt="item.ticket_image.alt"
                :src="item.ticket_image.url"
              />
                <!-- :src="require('../assets/' + item.img)" -->
              <!-- <prismic-image :field="item.ticket_image"/> -->
              <v-card-title>
                <!-- {{ $t(item.label) }} -->
                <prismic-rich-text :field="item.ticket_description"/>
              </v-card-title>
            </v-card>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </v-col>
    </v-row>


        <!-- <v-list nav dense>
          <v-list-item-group
            v-model="group"
            active-class="deep-blue--text text--accent-4"
          > -->
            <!-- <v-list-item v-for="(item, index) in consts.registerPaymentPages" :key="index" class="icon-item"
            link
            :href="item.path"
            target="_blank"
            >

            <v-list-item-title>{{ item.label }}</v-list-item-title>
          </v-list-item> -->
            <!-- <div class="my-2">
              <v-btn
                v-for="(item, index) in consts.registerPaymentPages"
                :key="index"
                rounded
                class="icon-item pa-ma-4 my-2"
                color="accent"
                link
                :href="item.path"
                target="_blank"
              >
                <v-list-item-title>{{ item.label }}</v-list-item-title>
              </v-btn>
            </div> -->
          <!-- </v-list-item-group>
        </v-list> -->
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "RegisterPage",
  components: {},
  props: {},
  data() {
    return {
      group: null,
      valid: false,
      showPass: false,
    };
  },
  computed: {
    ...mapGetters(["isLoggedUser", "consts", "constData"]),
  },
  methods: {},
};
</script>

<style scoped>
h1 {
  color: white;
}
</style>
